import React from 'react';
import clientLogo from "../../common/images/client-logo.svg";
import animIcon from "../../common/images/anim.svg";
import BasicInput from '../../application/components/basicInput';
import loginBackground from "../../common/images/bg-overwms2.jpg";

class Auth extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            login: {
                value: ''
            },
            password: {
                value: ''
            },
        };
    }

    handleInputChange = (e) => {
        const name = e.target.id;
        const value = e.target.value;
        const newValue = {
            value: value,
        }

        this.setState({
            [name]: newValue
        })
    }

    onFormSubmit = (e) => {
        e.preventDefault();
        const login = this.state.login.value;
        const password = this.state.password.value;
        this.props.login(login, password)
    }

    authForm = () => {
        return (
            <form
                className="d-flex flex-column"
                onSubmit={this.onFormSubmit}
            >
                <BasicInput
                    label="Login"
                    type="text"
                    id="login"
                    onChange={this.handleInputChange}
                    value={this.state.login.value}
                />

                <BasicInput
                    label="Hasło"
                    type="password"
                    id="password"
                    onChange={this.handleInputChange}
                    value={this.state.password.value}
                />

                <br />
                <button type="submit" className="btn btn-primary btn-wms btn-block text-uppercase mb-2 shadow-sm ">Zaloguj</button>
            </form>
        )
    }
    render() {
        return (<div className='d-flex justify-content-center align-items-center vh-100 login-container'>
            <div className="col-md-6">
                <div className="d-flex align-items-center h-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10 col-xl-7 mx-auto d-flex flex-column justify-content-between  h100vh">
                                <img src={clientLogo} className="login-client-logo mb-4 w-50" alt="logo" />
                                <div>
                                    <h5>Witaj ponownie</h5>
                                    <h3>Zaloguj się na swoje konto</h3>
                                    {this.authForm()}
                                </div>
                                <p>Potrzebujesz pomocy?<a href="mailto:pomoc@over-cloud.pl" className="text-muted"> <u>Skontaktuj się z biurem</u></a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-6 d-none d-md-flex blue-bg p-0 h-100" style={{ backgroundImage: `url(${loginBackground})`, backgroundRepeat: 'no-repeat' }}>
                {/* <div style={{ backgroundImage: `url(${bgLogowanie})`, width: '100%', height: '100%', position: 'absolute' }}></div> */}
                <img src={animIcon} className="login-bg-logo" alt="wms-logo" />
                {/* <img src={bgLogowanie} className='w-100' alt="logowanie" /> */}
            </div>
        </div>)
    }
}

export default Auth;