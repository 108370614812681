import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import Acl from "../../services/acl.service";

/* 
{
     productId: string;
     warehouse: string;
     onSelect: (option) => void;
     label: string;
     disabled?: boolean;
}
*/

export default class SeriesSelect extends React.Component {

    componentDidMount() {
        const { productId, getSeriesOptions, warehouse } = this.props;
        if (productId) {
            getSeriesOptions(productId, warehouse);
        }
    }

    onOptionSelect = (e) => {
        const { onSelect, seriesOptions } = this.props;
        const index = e.target.value;
        const option = seriesOptions[index];
        if (option) {
            console.log('onSelect', option);
            onSelect(option.seriesNr);
        }
    }

    renderOptions = () => {
        const { seriesOptions, warehouse, excludedSeries } = this.props;
        if (_.isArray(seriesOptions) && seriesOptions.length) {
            return _.sortBy(seriesOptions, (option) => parseInt(moment(option.expirationDate).format("X")) * -1).map((option, index) => {
                if (excludedSeries.includes(option.seriesNr)) return null;
                return <option value={index} key={`option-${index}`}>{`${option.seriesNr} (${moment(option.expirationDate).format("DD.MM.YYYY")}) - ${option.amount} ${option.unit}`}</option>
            });
        } else {
            return <option value={''}>{`Brak serii produktu w magazynie ${warehouse}`}</option>
        }
    }

    checkPermission = () => {
        const { auth } = this.props;
        console.log(auth, 'auth');
        if (_.get(auth, 'isAdmin', false)) {
            return true;
        } else {
            return Acl.isAllowedAction("product", "product", "get", _.get(auth, 'isAdmin', false));
        }
    }

    render() {
        const { disabled, label } = this.props;
        const options = this.renderOptions();
        const hasPermission = this.checkPermission();
        return (
            <div className="form-group">
                <label>{label}</label>
                <select
                    className="form-control"
                    placeholder="Wybierz serię"
                    disabled={!hasPermission || disabled}
                    onChange={(e) => { this.onOptionSelect(e) }}
                >
                    {options}
                </select>
            </div>
        );
    }
}