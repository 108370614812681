import _ from 'lodash';
import React from 'react';
import moment from "moment";

export default class DocumentProductsTable extends React.Component {
    getRows = () => {
        const { products } = this.props;
        if (products) {
            let lp = 0;
            return products.map((product, i) => {
                return product.series.map((series) => {
                    lp++;
                    return (
                        <tr key={i}>
                            <th className="product-table-cell" scope="row">{i + 1}</th>
                            <td className="product-table-cell">{product.name}</td>
                            <td className="product-table-cell">{series.seriesNr}</td>
                            <td className="">{moment(series.expirationDate).format("DD.MM.YYYY")}</td>
                            <td className="product-table-cell">{series.amount}</td>
                            <td className="product-table-cell">szt</td>
                        </tr>
                    )
                });
            })
        }
    }

    getCompletedProductRows = () => {
        const { products, completedProducts } = this.props;
        if (products) {
            let lp = 0;
            return products.map((product) => {
                let reservation = _.find(completedProducts, (r) => r.productId === product.productId);
                if (reservation) {
                    return reservation.series.map((series) => {
                        lp++;
                        return (
                            <tr key={lp}>
                                <th className="">{lp}</th>
                                <td className="">{product.name}</td>
                                <td className="">{series.seriesNr}</td>
                                <td className="">{series.expirationDate ? moment(series.expirationDate).format("DD.MM.YYYY") : "-"}</td>
                                <td className="">{series.amount}</td>
                                <td className="">szt</td>
                            </tr>
                        )
                    });
                }
                return null;
            });
        }
    }

    render() {
        const { products, completedProducts } = this.props;
        return (
            <table className="table table-bordered product-table mt-2" style={{ width: "100%" }}>
                <thead className="test">
                    <tr >
                        <th scope="col">Lp.</th>
                        <th scope="col">Nazwa</th>
                        <th>Seria</th>
                        <th scope="col">Data ważności</th>
                        <th scope="col">Ilość</th>
                        <th scope="col">Jm</th>
                    </tr>
                </thead>
                <tbody>
                    {_.isEmpty(completedProducts) ? this.getRows() : this.getCompletedProductRows()}
                </tbody>
            </table>
        )

    }
} 