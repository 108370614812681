import React from 'react';
import { connect } from 'react-redux';
import {
    clearDocument,
    getDocument,
    saveProductSeriesEdit
} from '../actions/document.actions';
import { setActiveDocument } from "../actions/documents.actions";
import DocumentProductSeriesEdit from '../components/documentProductSeriesEdit.component';
import '../documents.css';

class DocumentProductSeriesEditContainer extends React.Component {

    render() {
        return (
            <div>
                <DocumentProductSeriesEdit
                    {...this.props}
                />
            </div>
        )
    }
}

function mapStateToProps(store) {
    return {
        auth: store.auth,
        isAdmin: store.auth.isAdmin,
        document: store.document
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getDocument: (id) => {
            dispatch(getDocument(id))
        },
        clearDocument: () => {
            dispatch(clearDocument())
        },
        setActiveDocument: (activeDocument) => {
            dispatch(setActiveDocument(activeDocument));
        },
        saveProductSeriesEdit: (data, history) => {
            dispatch(saveProductSeriesEdit(data, history));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentProductSeriesEditContainer);