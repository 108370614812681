import Api from '../../services/api';
import { toast } from 'react-toastify';

const namespace = 'SERIES_OPTIONS';
export const SET_SERIES_OPTIONS = `SET_SERIES_OPTIONS_${namespace}`;
export const CLEAR_SERIES_OPTIONS = `CLEAR_SERIES_OPTIONS_${namespace}`;

export const setSeriesOptions = (seriesOptions) => (dispatch) => {
    dispatch({
        type: SET_SERIES_OPTIONS,
        seriesOptions
    });
}

export const clearSeriesOptions = () => (dispatch) => {
    dispatch({
        type: CLEAR_SERIES_OPTIONS
    });
}

export const getSeriesOptions = (productId, warehouse) => async (dispatch) => {
    let res = await Api.get(`/product/product/getProductPositions/${productId}?warehouse=${warehouse}`);

    if (res && res.success) {
        dispatch(setSeriesOptions(res.documents));
    } else {
        toast('Wystąpił błąd');
    }
}


