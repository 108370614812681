import React from 'react';

import DocumentHeader from './partials/documentHeader';
import DocumentAddressBox from './partials/documentAddressBox';
import DocumentFooter from './partials/documentFooter';
import DocumentShortTwoValues from './partials/documentShortTwoValues';
import DocumentSingleValue from './partials/documentSingleValue';
import DocumentProductsTable from './partials/documentProductsTable';

export default class DocumentRWNP extends React.Component {

    documentDetails = () => {
        if (this.props.document) {
            const { document: {
                products,
                documentNumber,
                note,
                info,
                salesDate,
                provider,
                customer,
                warehouseFrom,
                forDocument
            },
                completedProducts,
                printOptions } = this.props.document;

            // const provider = _.get(this.props.document, 'provider');
            // const customer = _.get(this.props.document, 'customer');

            return (
                <div>
                    <DocumentHeader
                        documentNumber={documentNumber}
                        name="Przychód wewnętrzny"
                        salesDate={salesDate}
                        forDocument={forDocument}
                    />
                    <div className="d-flex flex-row">
                        <DocumentAddressBox
                            postCode={(provider) ? provider.address.postCode : null}
                            street={(provider) ? provider.address.street : null}
                            houseNumber={(provider) ? provider.address.houseNumber : null}
                            city={(provider) ? provider.address.city : null}
                            name={(provider) ? provider.name : null}
                            nip={(provider) ? provider.nip : null}
                            phone={(provider) ? provider.phone : null}
                            phone2={(provider) ? provider.phone2 : null}
                            fax={(provider) ? provider.fax : null}
                            mail={(provider) ? provider.mail : null}
                        />

                        <DocumentAddressBox
                            postCode={(customer) ? customer.address.postCode : null}
                            street={(customer) ? customer.address.street : null}
                            houseNumber={(customer) ? customer.address.houseNumber : null}
                            city={(customer) ? customer.address.city : null}
                            name={(customer) ? customer.name : null}
                            nip={(customer) ? customer.nip : null}
                            phone={(customer) ? customer.phone : null}
                            phone2={(customer) ? customer.phone2 : null}
                            fax={(customer) ? customer.fax : null}
                            mail={(customer) ? customer.mail : null}
                            isCustomer={true}
                        />
                    </div>

                    <div className="d-flex flex-row">
                        <DocumentShortTwoValues
                            width={'100%'}
                            label1="Kod magazynu"
                            value1={warehouseFrom.code}
                            label2="Nazwa magazynu"
                            value2={warehouseFrom.name}
                        />
                    </div>

                    <div className="d-flex flex-row">
                        {(printOptions.note && note) ?
                            <DocumentSingleValue
                                width="100%"
                                value={note}
                            />
                            : null
                        }
                    </div>

                    <div className="d-flex flex-row">

                        {
                            (products) ?
                                <DocumentProductsTable
                                    products={products}
                                    completedProducts={completedProducts}
                                    printOptions={printOptions}
                                />
                                : null
                        }
                    </div>

                    <div className="d-flex flex-row">
                        <DocumentFooter />
                    </div>

                    <div className="d-flex flex-row">
                        {(printOptions.info && info) ?
                            <DocumentSingleValue
                                width="100%"
                                value={info}
                            />
                            : null
                        }
                    </div>

                </div>
            )
        }
    }
    render() {
        return (
            <div className="document-container">
                {this.documentDetails()}
            </div>
        )
    }
}