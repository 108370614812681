import _ from 'lodash';
import moment from "moment";
import React from 'react';
import { Link } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import ComponentNavbar from '../../application/components/componentNavbar';
import DeleteConfirmationModal from '../../application/components/deleteConfirmationModal';
import SingleDetail from '../../application/components/singleDetail';
import Acl from "../../services/acl.service";
import DictManager from '../../services/dict.service';
import DocumentMM from '../components/documentMM.component';
import DocumentPW from '../components/documentPW.component';
import DocumentRWK from '../components/documentRWK.component';
import DocumentRWNP from '../components/documentRWNP.component';
import DocumentWZ from '../components/documentWZ.component';
import DocumentWZK from '../components/documentWZK.component';


export default class DocumentDetails extends React.Component {

    constructor() {
        super();
        this.state = {
            modalVisibility: false,
            printModalOpen: false,
            activeTab: "products-tab"
        }
    }

    handleOpenModal = () => {
        this.setState({
            modalVisibility: true
        });
    }

    handleCloseModal = () => {
        this.setState({
            modalVisibility: false
        });
    }


    handleOpenPrintModal = () => {
        this.setState({
            printModalOpen: true
        });
    }

    handleClosePrintModal = () => {
        this.setState({
            printModalOpen: false
        });
    }

    componentDidMount = () => {
        const { setActiveDocument, getDocument } = this.props;
        const { id } = this.props.match.params;
        getDocument(id);
        setActiveDocument(id);
    }

    saveQuickEdit = () => {
        const { saveQuickEdit } = this.props;
        let data = {};
        let description = this.refs.description.value;
        let transportType = this.refs.transportType.value;
        let transportDescription = this.refs.transportDescription.value;
        let packagesAmount = this.refs.packagesAmount.value;
        let note = this.refs.note.value;
        let info = this.refs.info.value;

        if (description) {
            data.description = description;
        }
        if (transportType !== "0") {
            data.transportType = transportType;
        }
        if (transportDescription) {
            data.transportDescription = transportDescription;
        }
        if (packagesAmount) {
            data.packagesAmount = packagesAmount;
        }
        if (note) {
            data.note = note;
        }
        if (info) {
            data.info = info;
        }
        if (!_.isEmpty(data)) {
            saveQuickEdit(data);
        }
        this.handleCloseModal();
    }

    getEditModal = () => {
        const { document: { document } } = this.props;
        let options = DictManager.getOptionsByType(2);
        return (
            <div className="modal" role="dialog" style={{ backgroundColor: 'rgba(0, 0, 0, 0.3)', zIndex: 100000, display: 'flex', flex: 1 }}>
                <div className="modal-dialog modal-lg" role="document" style={{ width: "80vw" }}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Edytuj dokument</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                onClick={() => this.handleCloseModal()}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label>Opis dokumentu</label>
                                        <textarea className="form-control" ref="info" defaultValue={document.info || ""} placeholder="Wpisz opis dokumentu" />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label>Uwagi</label>
                                        <textarea className="form-control" ref="note" defaultValue={document.note || ""} placeholder="Wpisz uwagi" />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className={`form-group ${document.status == "3" ? "" : "d-none"}`}>
                                        <label>Opis do kompletacji</label>
                                        <textarea className="form-control" ref="description" defaultValue={document.description || ""} placeholder="Wpisz opis do kompletacji" />
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="form-group">
                                        <label>Typ transportu</label>
                                        <select className="form-control" ref="transportType" defaultValue={document.transportType || "0"}>
                                            {options}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="form-group">
                                        <label>Opis transportu</label>
                                        <input type="text" className="form-control" ref="transportDescription" defaultValue={document.transportDescription || ""} placeholder="Wpisz opis" />
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="form-group">
                                        <label>Ilość paczek</label>
                                        <input type="number" min="0" className="form-control" ref="packagesAmount" defaultValue={document.packagesAmount || ""} placeholder="Wpisz ilość paczek" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                style={{ width: '150px' }}
                                type='button'
                                className='btn btn-outline'
                                onClick={() => this.handleCloseModal()}
                            > Anuluj </button>
                            <button
                                style={{ width: '150px' }}
                                type='button'
                                className='btn btn-primary'
                                onClick={() => this.saveQuickEdit()}
                            > Zapisz </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    changePrintOptions = (e, name) => {
        const { setPrintOptions, document: { printOptions } } = this.props;
        const updatedPrintOptions = { ...printOptions, [name]: e.target.checked };

        setPrintOptions(e.target.checked, name);
        localStorage.setItem("printOptions", JSON.stringify(updatedPrintOptions));
    }

    getPrintModal = () => {
        const { document: { printOptions, document } } = this.props;
        let isCompleted = _.get(document, "status", null) === "3";

        return (
            <div className="modal" role="dialog" style={{ backgroundColor: 'rgba(0, 0, 0, 0.3)', zIndex: 100000, display: 'flex', flex: 1 }}>
                <div className="modal-dialog modal-lg" role="document" style={{ width: "80vw" }}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Pola do druku</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                onClick={() => this.handleClosePrintModal()}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <h6>Dokument</h6>
                            <div className="row">
                                <div className="col-4 mt-3">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" checked={printOptions.info} onChange={(e) => this.changePrintOptions(e, "info")} />
                                        <label className="form-check-label">
                                            Opis
                                        </label>
                                    </div>
                                </div>
                                <div className="col-4 mt-3">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" checked={printOptions.note} onChange={(e) => this.changePrintOptions(e, "note")} />
                                        <label className="form-check-label">
                                            Uwagi
                                        </label>
                                    </div>
                                </div>
                            </div>
                            {/* <h6 className="mt-4">Produkty</h6>
                            <div className="row">
                                {isCompleted && <div className="col-4 mt-3">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" checked={printOptions.seriesNr} onChange={(e) => this.changePrintOptions(e, "seriesNr")} />
                                        <label className="form-check-label">
                                            Seria
                                        </label>
                                    </div>
                                </div>}
                                <div className="col-4 mt-3">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" checked={printOptions.name} onChange={(e) => this.changePrintOptions(e, "name")} />
                                        <label className="form-check-label">
                                            Nazwa
                                        </label>
                                    </div>
                                </div>
                                <div className="col-4 mt-3">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" checked={printOptions.code} onChange={(e) => this.changePrintOptions(e, "code")} />
                                        <label className="form-check-label">
                                            Kod
                                        </label>
                                    </div>
                                </div>
                                <div className="col-4 mt-3">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" checked={printOptions.amount} onChange={(e) => this.changePrintOptions(e, "amount")} />
                                        <label className="form-check-label">
                                            Ilość
                                        </label>
                                    </div>
                                </div>
                                {isCompleted && <div className="col-4 mt-3">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" checked={printOptions.expirationDate} onChange={(e) => this.changePrintOptions(e, "expirationDate")} />
                                        <label className="form-check-label">
                                            Ważność
                                        </label>
                                    </div>
                                </div>}
                                {isCompleted && <div className="col-4 mt-3">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" checked={printOptions.productDescription} onChange={(e) => this.changePrintOptions(e, "productDescription")} />
                                        <label className="form-check-label">
                                            Opis
                                        </label>
                                    </div>
                                </div>}
                            </div> */}
                        </div>
                        <div className="modal-footer">
                            <button
                                style={{ width: '150px' }}
                                type='button'
                                className='btn btn-outline'
                                onClick={() => this.handleClosePrintModal()}
                            > Anuluj </button>
                            <ReactToPrint
                                trigger={() => <button style={{ width: '150px' }} className="btn btn-primary">Drukuj</button>}
                                content={() => this.componentRef}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }



    navbarButton = () => {
        const { document, isAdmin, handleGenerateDocumentCodes } = this.props;
        let allowQuickEdit = Acl.isAllowedAction("document", "document", "quickEdit", isAdmin);
        let canDelete = _.get(document, 'document.status') == "1" && Acl.isAllowedAction("document", "document", "delete", isAdmin);
        let canGenerateCode = Acl.isAllowedAction("document", "document", "generateDocumentCodes", isAdmin) && _.get(document, 'document.status') == "3" && !_.includes(["ZP", "DH", ""], _.get(document, 'document.type', '')) ? true : false;
        let canUndoCompletion = Acl.isAllowedAction("document", "document", "undoDocument", isAdmin) && _.get(document, 'document.status') == "3" && _.get(document, "action") !== "internal";
        let allowAdvancedEdit = Acl.isAllowedAction("document", "document", "advancedEdit", isAdmin) && _.get(document, 'document.status') == "1";
        let allowManualCompletion = Acl.isAllowedAction("document", "document", "manualCompletion", isAdmin) && _.get(document, 'document.status') == "1" && _.get(document, "document.action") === "subtract";
        // const allowSeriesEdit = Acl.isAllowedAction("document", "document", "editProductSeries", isAdmin) && _.get(document, 'document.status') == "1";

        return (
            <div className="btn-group">
                <div className="dropdown">
                    <button className="btn btn-outline-info dropdown-toggle"
                        type="button" id="documentActions" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        Akcje
                    </button>
                    <div className="dropdown-menu" aria-labelledby="documentActions">
                        {canGenerateCode && <button className="dropdown-item" onClick={() => { handleGenerateDocumentCodes() }}>Drukuj kody</button>}
                        {canUndoCompletion && <button className="dropdown-item" onClick={() => { this.handleOpenUndoModal() }}>Wycofaj dokument</button>}
                        {allowQuickEdit && <button className="dropdown-item" onClick={() => { this.handleOpenModal() }}>Szybka edycja</button>}
                        {allowAdvancedEdit && <Link className="dropdown-item text-dark" to={`/documents/edit/${document.document._id}`}>Zaawansowana edycja</Link>}
                        {/* {allowSeriesEdit && <Link className="dropdown-item text-dark" to={`/documents/seriesEdit/${document.document._id}`}>Edycja serii produktów</Link>} */}
                        {allowManualCompletion && <Link className="dropdown-item text-dark" to={`/documents/completion/${document.document._id}`}>Kompletacja ręczna</Link>}
                    </div>
                </div>
                {canDelete && <button className="btn btn-outline-danger" onClick={() => this.handleOpenDeleteModal()}>Usuń</button>}
                <button className='btn btn-outline-primary' onClick={() => this.handleOpenPrintModal()}> Drukuj</button>
            </div>
        )
    }

    getPrintDocument = () => {
        const { document } = this.props;
        const type = _.toUpper(_.get(document, 'document.type'));

        if (type) {
            switch (type) {
                case 'MM+':
                case 'MM-':
                case 'PZ2':
                case 'PZN2': {
                    return <DocumentMM {...this.props}
                        ref={el => (this.componentRef = el)}
                    />
                }
                case 'WZ': {
                    return (
                        <DocumentWZ {...this.props}
                            ref={el => (this.componentRef = el)}
                        />
                    )
                }
                case 'WZK': {
                    return (
                        <DocumentWZK {...this.props}
                            ref={el => (this.componentRef = el)}
                        />
                    )
                }
                case 'RWK':
                case 'RWNPK': {
                    return (
                        <DocumentRWK {...this.props}
                            ref={el => (this.componentRef = el)}
                        />
                    )
                }
                case 'RWNP':
                case 'RW':
                    {
                        return (
                            <DocumentRWNP {...this.props}
                                ref={el => (this.componentRef = el)}
                            />
                        )
                    }
                case 'PW':
                    {
                        return (
                            <DocumentPW {...this.props}
                                ref={el => (this.componentRef = el)}
                            />
                        )
                    }
                default:
            }
        }
    }

    getStatusValue = (status) => {
        switch (status) {
            case '1':
                return 'Do realizacji';
            case '2':
                return 'W realizacji';
            case '3':
                return 'Zrealizowany';
            default:
                return null;
        }
    }

    renderReservations = () => {
        const { document: { document, reservations } } = this.props;
        if (!document) {
            return null;
        }
        if (_.isArray(reservations)) {
            let lp = 0;
            let rows = reservations.map((reservation) => {
                let documentProduct = _.find(document.products, (p) => {
                    return p.productId === reservation.productId;
                });
                if (documentProduct) {
                    let tds = reservation.series.map((reservationSeries) => {
                        lp++;
                        return <tr key={`reservation-${lp}`}>
                            <td>{lp}</td>
                            <td>{documentProduct.name}</td>
                            <td>{reservationSeries.seriesNr}</td>
                            <td>{reservationSeries.positionCode}</td>
                            <td>{reservationSeries.amount}</td>
                            <td>{documentProduct.unit}</td>
                        </tr>
                    });
                    return tds;
                }
            });

            return (
                <table className="table" style={{ width: "100%" }}>
                    <thead className="thead-dark">
                        <tr >
                            <th scope="col">Lp.</th>
                            <th scope="col">Nazwa</th>
                            <th scope="col">Seria</th>
                            <th scope="col">Pozycja</th>
                            <th scope="col">Ilość</th>
                            <th scope="col">Jm</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows}
                    </tbody>
                </table>
            )
        } else {
            return (
                <div className="single-detail-value"> - </div>
            );
        }
    }

    renderCompletedProducts = () => {
        const { document: { document } } = this.props;
        if (!document) {
            return null;
        }

        if (_.isArray(document.completionResult)) {
            let lp = 0;
            let rows = document.completionResult.map((result) => {
                let documentProduct = _.find(document.products, (p) => {
                    return p.productId === result.productId;
                });
                if (documentProduct) {
                    let tds = result.series.map((series) => {
                        lp++;
                        return <tr key={`result-${lp}`}>
                            <td>{lp}</td>
                            <td>{documentProduct.name}</td>
                            <td>{series.documentSeriesNr}</td>
                            <td>{series.seriesNr}</td>
                            <td>{series.positionCode}</td>
                            <td title={result.description}>{_.truncate(result.description, { length: 10 })}</td>
                            <td>{series.amount}</td>
                            <td>{documentProduct.unit}</td>
                        </tr>
                    });
                    return tds;
                }
            });

            return (
                <table className="table" style={{ width: "100%" }}>
                    <thead className="thead-dark">
                        <tr >
                            <th scope="col">Lp.</th>
                            <th scope="col">Nazwa</th>
                            <th scope="col">Seria w dokumencie</th>
                            <th scope="col">Seria skompletowana</th>
                            <th scope="col">Pozycja</th>
                            <th scope="col">Opis</th>
                            <th scope="col">Ilość</th>
                            <th scope="col">Jm</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows}
                    </tbody>
                </table>
            )
        } else {
            return (
                <div className="single-detail-value"> - </div>
            );
        }
    }

    renderProducts = () => {
        const { document: { document } } = this.props;
        if (!document) {
            return null;
        }

        if (_.isArray(document.products)) {
            let lp = 0;
            let rows = document.products.map((product) => {
                let tds = product.series.map((series) => {
                    lp++;
                    return <tr key={`product-${lp}`} className={`${product.completionDisabled ? "table-danger" : ""}`}>
                        <td>{lp}</td>
                        <td><Link to={`/products/details/${product.productId}`} className="text-dark">{product.name}</Link></td>
                        <td>{moment(series.expirationDate).format("DD.MM.YYYY")}</td>
                        <td>{series.seriesNr}</td>
                        <td>{series.amount}</td>
                        <td>{product.unit}</td>
                    </tr>
                });
                return tds;
            });

            return (
                <table className="table" style={{ width: "100%" }}>
                    <thead className="thead-dark">
                        <tr >
                            <th scope="col">Lp.</th>
                            <th scope="col">Nazwa</th>
                            <th scope="col">Data ważności</th>
                            <th scope="col">Seria</th>
                            <th scope="col">Ilość</th>
                            <th scope="col">Jm</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows}
                    </tbody>
                </table>);
        } else {
            return (
                <div className="single-detail-value"> - </div>
            )
        }
    }

    renderZpProducts = () => {
        const { document: { document } } = this.props;
        if (!document) {
            return null;
        }
        if (_.isArray(document.products)) {
            let rows = document.products.map((product, index) => {
                return (
                    <tr key={index}>
                        <td className="">{index + 1}</td>
                        <td className="">{product.seriesNr}</td>
                        <td className="">{product.ean}</td>
                        <td className="">{product.name}</td>
                        <td className="">{product.amount}</td>
                        <td className="">{product.unit}</td>
                    </tr>
                );
            });
            return (
                <table className="table" style={{ width: "100%" }}>
                    <thead className="thead-dark">
                        <tr >
                            <th scope="col">Lp.</th>
                            <th scope="col">Seria</th>
                            <th scope="col">EAN</th>
                            <th scope="col">Nazwa</th>
                            <th scope="col">Ilość</th>
                            <th scope="col">Jm</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows}
                    </tbody>
                </table>
            );
        } else {
            return (
                <div className="single-detail-value"> - </div>
            );
        }
    }

    getInspectionStatusName = (status) => {
        let names = {
            "1": "Do sprawdzenia",
            "2": "W trakcie sprawdzania",
            "3": "Sprawdzony"
        }
        return names[status] || "Nieznany";
    }

    getDocumentDetails = () => {
        const { document: { document } } = this.props;
        if (!document) {
            return null;
        }

        const status = _.get(document, 'status');
        const statusValue = this.getStatusValue(status);
        const documentNumber = _.get(document, 'documentNumber')
        const info = _.get(document, 'info');
        const note = _.get(document, 'note');
        const createdAt = _.get(document, 'createdAt');
        const description = _.get(document, 'description');
        const creator = _.get(document, 'creator.name');
        let transportType = _.get(document, 'transportType');
        if (transportType) {
            transportType = DictManager.getNameByValue(2, transportType);
        }
        const transportDescription = _.get(document, 'transportDescription');
        const completionStart = _.get(document, 'completionStart');
        const completionEnd = _.get(document, 'completionEnd');
        const positionFrom = _.get(document, 'positionFrom');
        const positionTo = _.get(document, 'positionTo');
        const warehouseFrom = _.get(document, 'warehouseFrom');
        const warehouseTo = _.get(document, 'warehouseTo');
        const completionUser = _.get(document, 'completionUser');
        const completionTime = _.get(document, 'completionTime');
        const provider = _.get(document, 'provider');
        const customer = _.get(document, 'customer');
        const correctionInfo = _.get(document, "correctionInfo");
        const tabs = this.getDetailsTabs();

        const activeTab = this.state.activeTab;

        const freebies = _.get(document, 'freebies');

        const inspectionStatus = _.get(document, 'inspection.status');
        const inspectionUser = _.get(document, 'inspection.inspectionUser.name');
        const inspectedAt = _.get(document, 'inspection.inspectedAt');

        return (
            <div className="mb-5">
                <div className="document-single-details-container">
                    <SingleDetail
                        label={'Status dokumentu'}
                        value={statusValue}
                    />
                    <SingleDetail
                        label={'Numer dokumentu'}
                        value={documentNumber}
                    />
                    {correctionInfo && <SingleDetail
                        label={'Korekta do'}
                        value={correctionInfo.documentNumber}
                    />}
                    {creator && <SingleDetail
                        label={'Dodany przez'}
                        value={creator}
                    />}

                    {createdAt && <SingleDetail
                        label={'Data dodania'}
                        value={moment(createdAt).format("YYYY-MM-DD HH:mm")}
                    />}
                    {warehouseFrom && warehouseFrom.name && <SingleDetail
                        label={'Magazyn'}
                        value={warehouseFrom.name}
                    />}
                    {warehouseTo && warehouseTo.name && <SingleDetail
                        label={'Magazyn powiązany'}
                        value={warehouseTo.name}
                    />}

                    {positionFrom && positionFrom.code && <SingleDetail
                        label={'Pozycja z'}
                        value={positionFrom.code}
                    />}
                    {positionTo && positionTo.code && <SingleDetail
                        label={'Pozycja na'}
                        value={positionTo.code}
                    />}
                    {transportType && <SingleDetail
                        label={'Typ transportu'}
                        value={transportType}
                    />}
                    {completionUser && completionUser.name && <SingleDetail
                        label={'Realizujący'}
                        value={completionUser.name}
                    />}
                    {completionStart && <SingleDetail
                        label={'Data rozpoczęcia realizacji'}
                        value={moment(completionStart).format("YYYY-MM-DD HH:mm")}
                    />}
                    {completionEnd && <SingleDetail
                        label={'Data zakończenia realizacji'}
                        value={moment(completionEnd).format("YYYY-MM-DD HH:mm")}
                    />}
                    {_.isNumber(completionTime) && <SingleDetail
                        label={'Czas realizacji (minut)'}
                        value={completionTime}
                    />}
                    <SingleDetail
                        label={'Status sprawdzania'}
                        value={this.getInspectionStatusName(inspectionStatus)}
                    />
                    {inspectedAt && <SingleDetail
                        label={'Data sprawdzenia'}
                        value={moment(inspectedAt).format("YYYY-MM-DD HH:mm")}
                    />}
                    {inspectionUser && <SingleDetail
                        label={'Sprawdzający'}
                        value={inspectionUser}
                    />}
                </div>
                <div className="mt-2 mb-2">
                    <hr />
                    <SingleDetail
                        label={'Opis dokumentu'}
                        value={info}
                    />
                </div>
                {note && <div className="mt-2 mb-2">
                    <hr />
                    <SingleDetail
                        label={'Uwagi'}
                        value={note}
                    />
                </div>}

                {transportDescription && <div className="mt-2 mb-2">
                    <hr />
                    <SingleDetail
                        label={'Opis transportu'}
                        value={transportDescription}
                    />
                </div>}

                {provider && provider.name &&
                    <div>
                        <hr />

                        <div className="document-details-address-box-container">
                            {provider && provider.name && <SingleDetail
                                smallFont={true}
                                label={'Dostawca'}
                                value={provider.name}
                            />}
                            {provider && provider.address && <SingleDetail
                                smallFont={true}
                                label={'Adres'}
                                value={
                                    `${_.get(provider.address, 'postCode')} ${_.get(provider.address, 'city')}, ${_.get(provider.address, 'street')} ${_.get(provider.address, 'houseNumber')}`
                                }
                            />}
                            {provider && provider.nip && <SingleDetail
                                smallFont={true}
                                label={'NIP'}
                                value={provider.nip}
                            />}
                            {provider && provider.phone && <SingleDetail
                                smallFont={true}
                                label={'Telefon'}
                                value={
                                    `${_.get(provider, 'phone', '')}  ${_.get(provider, 'phone2', '')}`
                                }
                            />}
                            {provider && provider.fax && <SingleDetail
                                smallFont={true}
                                label={'Fax'}
                                value={provider.fax}
                            />}
                            {provider && provider.mail && <SingleDetail
                                smallFont={true}
                                label={'E-mail'}
                                value={provider.mail}
                            />}
                        </div>
                    </div>
                }


                {customer && customer.name &&
                    <div>
                        <hr />

                        <div className="document-details-address-box-container">
                            {customer && customer.name && <SingleDetail
                                smallFont={true}
                                label={'Kontrahent'}
                                value={customer.name}
                            />}
                            {customer && customer.address && <SingleDetail
                                smallFont={true}
                                label={'Adres'}
                                value={
                                    `${_.get(customer.address, 'postCode', '')} ${_.get(customer.address, 'city', '')}, ${_.get(customer.address, 'street', '')} ${_.get(customer.address, 'houseNumber', '')}`
                                }
                            />}
                            {customer && customer.nip && <SingleDetail
                                smallFont={true}
                                label={'NIP'}
                                value={customer.nip}
                            />}
                            {customer && customer.phone && <SingleDetail
                                smallFont={true}
                                label={'Telefon'}
                                value={
                                    `${_.get(customer, 'phone', '')}  ${_.get(customer, 'phone2', '')}`
                                }
                            />}
                            {customer && customer.fax && <SingleDetail
                                smallFont={true}
                                label={'Fax'}
                                value={customer.fax}
                            />}
                            {customer && customer.mail && <SingleDetail
                                smallFont={true}
                                label={'E-mail'}
                                value={customer.mail}
                            />}
                        </div>
                    </div>
                }

                <hr />

                {/* Jeśli produkt ma status w realizacji lub jeśli jest data stopu kompletacji wyswietlamy zakładkę rezerwacje */}
                {<div> {tabs}  {this.renderProductsTable()}</div>}
                {freebies && freebies.length > 0 &&
                    this.renderFreebies(freebies)
                }

                {status == "3" &&
                    <SingleDetail
                        label={'Opis skompletowanego dokumentu'}
                        value={description || "-"}
                    />
                }
            </div>
        )
    }

    renderProductsTable = () => {
        const { activeTab } = this.state;
        const { document: { document } } = this.props;
        if (!document) {
            return null;
        }
        if (document.type === "ZP") {
            return this.renderZpProducts();
        }
        if (activeTab === 'products-tab') {
            switch (document.status) {
                case 1:
                case "1":
                case 2:
                case "2":
                    return this.renderProducts();
                case 3:
                case "3":
                    return this.renderCompletedProducts();
            }
        } else {
            return this.renderReservations();
        }
    }

    setActiveTab = (name) => {
        const { activeTab } = this.state;
        if (name !== activeTab) {
            document.getElementById(activeTab).classList.remove("active");
            document.getElementById(name).classList.add("active");
            this.setState({ activeTab: name });

        }
    }

    getDetailsTabs = () => {
        const { document: { document } } = this.props;
        if (!document) {
            return null;
        }
        return (
            <ul className="nav nav-tabs mb-3 user-select-none">
                <li className="nav-item">
                    <span className="nav-link active" id="products-tab" onClick={() => this.setActiveTab("products-tab")}>Produkty</span>
                </li>
                {document.status == 2 && <li className="nav-item">
                    <span className="nav-link" id="reservation-tab" onClick={() => this.loadReservations()}>Rezerwacje</span>
                </li>}
            </ul>
        )
    }

    loadReservations = () => {
        const { getReservations } = this.props;
        getReservations();
        this.setActiveTab("reservation-tab");
    }

    renderFreebies = (freebies) => {
        return (
            <div>
                <hr />

                <div className="single-detail-label">
                    Gratisy
                </div>

                <table className="table" style={{ width: "100%" }}>
                    <thead className="thead-dark">
                        <tr >
                            <th scope="col">Lp.</th>
                            <th scope="col">Nazwa</th>
                            <th scope="col">Opis</th>
                            <th scope="col">Ilość</th>
                            <th scope="col">Skompletowane</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            freebies.map((freebie, i) => {
                                return (
                                    <tr key={i}>
                                        <th className="" scope="row">{i + 1}</th>
                                        <td className="">{_.get(freebie, 'name', '-')}</td>
                                        <td className="">{_.get(freebie, 'description', '-')}</td>
                                        <td className="">{_.get(freebie, 'amount', '-')}</td>
                                        <td className="">{_.get(freebie, 'collected', '-')}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        )
    }

    handleOpenDeleteModal = () => {
        this.setState({
            showDeleteModal: true
        });
    }

    handleCloseDeleteModal = () => {
        this.setState({
            showDeleteModal: false
        });
    }

    renderDeleteModal = () => {
        return (
            <DeleteConfirmationModal
                title="Usuwanie dokumentu"
                msg="Czy na pewno chcesz usunąć ten dokument?"
                handleCloseModal={this.handleCloseDeleteModal}
                confirmAction={this.handleDeleteDocument}
            />);
    }

    handleOpenUndoModal = () => {
        this.setState({
            showUndoModal: true
        });
    }

    handleCloseUndoModal = () => {
        this.setState({
            showUndoModal: false
        });
    }

    renderUndoModal = () => {
        return (
            <DeleteConfirmationModal
                title="Wycofanie dokumentu"
                msg="Czy na pewno chcesz wycofać ten dokument?"
                handleCloseModal={this.handleCloseUndoModal}
                confirmAction={this.handleUndoDocument}
            />);
    }

    handleUndoDocument = () => {
        const { undoDocument, history } = this.props;
        undoDocument(history);
        this.handleCloseUndoModal();
    }


    handleDeleteDocument = () => {
        const { history, match, deleteDocument } = this.props;
        let id = _.get(match, 'params.id');
        if (id) {
            deleteDocument(id, history);
        }
        this.handleCloseDeleteModal();
    }

    render() {
        const document = this.props.document;
        const type = _.get(document, 'document.type');
        const deleteModal = this.state.showDeleteModal ? this.renderDeleteModal() : null;
        const undoModal = this.state.showUndoModal ? this.renderUndoModal() : null;
        const modal = (this.state.modalVisibility) ? this.getEditModal() : null;
        const printModal = this.state.printModalOpen ? this.getPrintModal() : null;
        const printView = this.getPrintDocument();
        const details = this.getDocumentDetails();

        return (
            <div>
                {deleteModal}
                {modal}
                {printModal}
                {undoModal}
                <div className="container">
                    <ComponentNavbar
                        title="Dokument"
                        button={(type !== 'ZP') ? this.navbarButton() : null}
                    />
                    {details}

                    <div className="d-none">
                        {printView}
                    </div>
                </div>
            </div>

        )
    }

    componentWillUnmount() {
        const { clearDocument, clearReservations, resetPrintOptions } = this.props;
        clearDocument();
        clearReservations();
        resetPrintOptions();
    }
}