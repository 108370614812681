import React from 'react';
import { connect } from 'react-redux';
import { getSeriesOptions, clearSeriesOptions } from '../actions/seriesSelect.actions';
import SeriesSelect from '../components/seriesSelect.component';

class SeriesSelectContainer extends React.Component {
    render() {
        return (
            <SeriesSelect {...this.props} />
        )
    }
}

function mapStateToProps(store) {
    return {
        seriesOptions: store.seriesSelect.seriesOptions,
        auth: store.auth
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getSeriesOptions: (productId, warehouse) => {
            dispatch(getSeriesOptions(productId, warehouse));
        },
        clearSeriesOptions: () => {
            dispatch(clearSeriesOptions());
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SeriesSelectContainer);