import produce from "immer";

import {
    SET_SERIES_OPTIONS,
    CLEAR_SERIES_OPTIONS
} from '../actions/seriesSelect.actions';

const seriesSelectDefaultState = {
    seriesOptions: []
}

const seriesSelectReducer = (state = seriesSelectDefaultState, action) => {
    return produce(state, draft => {
        switch (action.type) {
            case SET_SERIES_OPTIONS: {
                draft.seriesOptions = action.seriesOptions;
                break;
            }
            case CLEAR_SERIES_OPTIONS: {
                draft.seriesOptions = [];
                break;
            }
            default: {
                return draft;
            }
        }
    });

}

export default seriesSelectReducer;