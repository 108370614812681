import _ from 'lodash';
import moment from "moment";
import React from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import SeriesSelect from '../../application/containers/seriesSelect.container';


export default class DocumentProductSeriesEdit extends React.Component {

    constructor() {
        super();
        this.state = {
            showConfirmModal: false,
            showEditModal: false,
            editedProduct: null,
            editedSeries: null,
            newSeries: [{
                seriesNr: '',
                amount: 0,
            }],
            comment: '',
            error: null
        }
    }

    componentDidMount = () => {
        const { setActiveDocument, getDocument } = this.props;
        const { id } = this.props.match.params;
        getDocument(id);
        setActiveDocument(id);
    }

    saveProductSeriesEdit = () => {
        const { saveProductSeriesEdit } = this.props;
        const { editedSeries, newSeries, comment, editedProduct } = this.state;
        let errors = [];
        newSeries.forEach((s, i) => {
            if (!s.seriesNr) {
                errors.push(`Brak numeru serii na pozycji ${i + 1}`);
            }
            if (!s.amount) {
                errors.push(`Brak ilości serii na pozycji ${i + 1}`);
            }
        });
        if (this.amountLeft() !== 0) {
            errors.push("Ilość produktu w nowych seriach musi być zgodna ze starą serią");
        }
        if (!comment) {
            errors.push("Komentarz jest wymagany");
        }

        if (errors.length) {
            this.setState({ error: errors.join(". ") });
            toast("Popraw błędy w formularzu");
        } else {
            saveProductSeriesEdit({ productId: editedProduct.productId, newSeries, oldSeriesNr: editedSeries.seriesNr, comment });
        }
    }

    setNewSeriesAmount = (index, value) => {
        const { newSeries } = this.state;
        const changedNewSeries = JSON.parse(JSON.stringify(newSeries));
        changedNewSeries[index].amount = value;
        this.setState({ newSeries: changedNewSeries, error: null });
    }

    setNewSeriesSeriesNr = (index, value) => {
        const { newSeries } = this.state;
        const changedNewSeries = JSON.parse(JSON.stringify(newSeries));
        changedNewSeries[index].seriesNr = value;
        this.setState({ newSeries: changedNewSeries, error: null });
    }

    addNewSeries = () => {
        const { newSeries } = this.state;
        const changedNewSeries = JSON.parse(JSON.stringify(newSeries));
        changedNewSeries.push({ seriesNr: '', amount: 0 });
        this.setState({ newSeries: changedNewSeries, error: null });
    }

    deleteNewSeries = (index) => {
        const { newSeries } = this.state;
        const changedNewSeries = JSON.parse(JSON.stringify(newSeries));
        changedNewSeries.splice(index, 1);
        this.setState({ newSeries: changedNewSeries, error: null });
    }

    amountLeft = () => {
        const { newSeries, editedSeries } = this.state;
        let amount = 0;
        newSeries.forEach((s) => amount += Math.abs(s.amount));
        return Math.abs(editedSeries.amount) - amount;
    }

    getEditModal = () => {
        const { document: { document } } = this.props;
        const { editedSeries, newSeries, comment, editedProduct, showEditModal, error } = this.state;
        if (!showEditModal || !editedProduct || !editedSeries) return null;
        return (
            <div className="modal" role="dialog" style={{ backgroundColor: 'rgba(0, 0, 0, 0.3)', zIndex: 100000, display: 'flex', flex: 1 }}>
                <div className="modal-dialog modal-lg" role="document" style={{ width: "80vw" }}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Edytuj serię produktu {editedProduct.name}</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                onClick={() => this.handleCloseEditModal()}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label>Stara seria</label>
                                        <input disabled className="form-control" defaultValue={editedSeries.seriesNr} placeholder="Stara seria" />
                                    </div>
                                </div>

                                {
                                    newSeries.map((s, i) => {
                                        let excludedSeries = [editedSeries.seriesNr];
                                        newSeries.forEach((ns, nsi) => {
                                            if (nsi !== i) {
                                                excludedSeries.push(ns.seriesNr);
                                            }
                                        })
                                        return (
                                            <div key={`edit${i}`} className='col-12 row'>
                                                <div className='col-6'>
                                                    <SeriesSelect
                                                        onSelect={(value) => this.setNewSeriesSeriesNr(i, value)}
                                                        excludedSeries={excludedSeries}
                                                        productId={editedProduct.productId}
                                                        label={"Nowa seria"}
                                                        warehouse={document.warehouseFrom.code} />
                                                    {/* <div className="form-group">
                                                        <label>Nowa seria</label>
                                                        <input className="form-control" onChange={(e) => this.setNewSeriesSeriesNr(i, e.target.value)} value={s.seriesNr} placeholder="Nowa seria" />
                                                    </div> */}
                                                </div>
                                                <div className='col-4'>
                                                    <div className="form-group">
                                                        <label>Nowa ilość</label>
                                                        <input type="number" className="form-control" onChange={(e) => this.setNewSeriesAmount(i, e.target.value)} value={s.amount} placeholder="Nowa seria" />
                                                    </div>
                                                </div>
                                                <div className='col-2 d-flex align-items-center justify-content-end'>
                                                    {i > 0 && <button className='btn btn-danger btn-sm' onClick={(e) => this.deleteNewSeries(i)}>Usuń serię</button>}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                <div className='col-12 mb-4' onClick={this.addNewSeries}>
                                    <button className='btn btn-secondary btn-sm'>Dodaj serię</button>
                                    <h6 className='mt-2'>Pozostała ilość: {this.amountLeft()}</h6>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label>Komentarz</label>
                                        <textarea className="form-control" onChange={(e) => this.setState({ comment: e.target.value })} value={comment} placeholder="Wpisz komentarz" />
                                    </div>
                                </div>
                                {error && <div className='col-12'>
                                    <p className='text-danger text-bold'>{error}</p>
                                </div>}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                style={{ width: '150px' }}
                                type='button'
                                className='btn btn-outline'
                                onClick={() => this.handleCloseEditModal()}
                            > Anuluj </button>
                            <button
                                style={{ width: '150px' }}
                                type='button'
                                className='btn btn-primary'
                                onClick={() => this.saveProductSeriesEdit()}
                            > Zapisz </button>
                        </div>
                    </div>
                </div>
            </div >
        );
    }

    handleShowEditModal = (editedProduct, editedSeries) => {
        this.setState({
            editedProduct, editedSeries, showEditModal: true, error: null, newSeries: [{
                seriesNr: '',
                amount: 0,
            }]
        });
    }

    handleCloseEditModal = () => {
        this.setState({
            editedProduct: null, editedSeries: null, showEditModal: false, error: null, newSeries: [{
                seriesNr: '',
                amount: 0,
            }]
        });
    }

    renderProducts = () => {
        const { document: { document } } = this.props;
        if (!document) {
            return null;
        }

        if (_.isArray(document.products)) {
            let lp = 0;
            let rows = document.products.map((product) => {
                let tds = product.series.map((series) => {
                    lp++;
                    return <tr key={`product-${lp}`} className={`${product.completionDisabled ? "table-danger" : ""}`}>
                        <td>{lp}</td>
                        <td><Link to={`/products/details/${product.productId}`} className="text-dark">{product.name}</Link></td>
                        <td>{moment(series.expirationDate).format("DD.MM.YYYY")}</td>
                        <td>{series.seriesNr}</td>
                        <td>{series.amount}</td>
                        <td>
                            <button className='btn btn-primary btn-sm' onClick={() => this.handleShowEditModal(product, series)}>Edytuj serię</button>
                        </td>
                    </tr>
                });
                return tds;
            });

            return (
                <table className="table" style={{ width: "100%" }}>
                    <thead className="thead-dark">
                        <tr >
                            <th scope="col">Lp.</th>
                            <th scope="col">Nazwa</th>
                            <th scope="col">Data ważności</th>
                            <th scope="col">Seria</th>
                            <th scope="col">Ilość</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows}
                    </tbody>
                </table>);
        } else {
            return (
                <div className="single-detail-value"> - </div>
            )
        }
    }

    render() {
        const document = this.props.document;
        if (!document) return null;
        const documentNumber = _.get(document, 'document.documentNumber', '')

        return (
            <div className='row'>
                {this.getEditModal()}
                <div className='col-12 text-center mb-5'>
                    <h4>Edycja serii dokumentu {documentNumber}</h4>
                </div>
                <div className='col-12 pl-5 pr-5'>
                    {this.renderProducts()}
                </div>
            </div>
        )
    }

    componentWillUnmount() {
        const { clearDocument } = this.props;
        clearDocument();
    }
}